@mixin accessible-focus-state() {
  &:focus-visible {
    @apply outline-none bg-cc-yellow no-underline hover:no-underline hover:bg-cc-yellow group-hover:bg-cc-yellow;
    @apply text-cc-black #{!important};

    &:not(.no-path-fill) {
      path {
        @apply fill-cc-black #{!important};
      }
    }
  }
}

a,
.link,
button {
  @include accessible-focus-state();
  @apply focus-visible:shadow-[0_-3px_0_0_theme(colors.cc.yellow),0_1px_0_0_theme(colors.cc.yellow),0_4px_0_0_theme(colors.cc.black)];
}

.button  {
  @include accessible-focus-state();
  @apply focus-visible:shadow-[0_0_0_4px_theme(colors.cc.yellow),inset_0_0_0_4px_theme(colors.cc.black)];
}

/* Fallback for browsers without :focus-visible support */
@supports not selector(:focus-visible) {
  a,
  .link,
  button {
    @include accessible-focus-state();
    @apply focus-visible:shadow-[0_-3px_0_0_theme(colors.cc.yellow),0_1px_0_0_theme(colors.cc.yellow),0_4px_0_0_theme(colors.cc.black)];
  }

  .button {
    @include accessible-focus-state();
    @apply focus-visible:shadow-[0_0_0_4px_theme(colors.cc.black)];
  }
}

.visually-hidden {
  @apply absolute -left-[9999px] top-auto w-[1px] h-[1px] overflow-hidden;
}

@media (prefers-reduced-motion: reduce) {
  * {
    @apply animate-none #{!important};
    @apply transition-none #{!important};
  }
}

.skip-link:focus-visible {
  @include accessible-focus-state();
  @apply focus-visible:shadow-[0_-3px_0_0_theme(colors.cc.yellow),0_1px_0_0_theme(colors.cc.yellow),0_4px_0_0_theme(colors.cc.black)];
  @apply fixed top-[1px] left-[1px] w-auto h-auto z-[99];
}
