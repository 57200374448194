.lc--nbm {
  & > *:last-child {
    @apply mb-0;
  }
}

.fc--ntm {
  & > *:first-child {
    @apply mt-0;
  }
}

.curve-graphic-size {
  @apply bg-[length:100%_100%] h-[24px] md:h-[32px] lg:h-[48px];
}