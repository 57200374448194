input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  @apply hidden;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  @apply px-[8px] py-[4px] border-2 border-cc-black w-full rounded placeholder-cc-black-70;
  @apply mb-4 md:mb-4-lg;

  &:focus {
    @apply outline-none border-cc-black;
    @apply shadow-[0_0_0_4px_theme(colors.cc.yellow),inset_0_0_0_4px_theme(colors.cc.black)];
  }
}

input[type=checkbox],
input[type=radio] {
  @apply appearance-none w-[40px] h-[40px] flex-shrink-0 flex-grow-0 border-2 border-cc-black bg-white;

  &:focus {
    @apply outline-none border-cc-black;
    @apply shadow-[0_0_0_4px_theme(colors.cc.yellow),inset_0_0_0_4px_theme(colors.cc.black)];
  }
}

input[type=checkbox] {
  @apply rounded;

  &:checked {
    @apply bg-[url('/dist/images/icons/functional/checkbox-checked.svg')] bg-no-repeat bg-center bg-transparent;
  }
}

input[type=radio] {
  @apply rounded-full;

  &:checked {
    @apply bg-[url('/dist/images/icons/functional/radio-checked.svg')] bg-no-repeat bg-center bg-transparent;
  }
}

textarea {
  @apply min-h-[124px];
}

label {
  @apply block mb-[8px];
}

// form buttons
// form button:not(.custom-button),
// form [type=button]:not(.custom-button), 
// form [type=submit]:not(.custom-button) {
//   @apply py-[13px] px-[28px] md:py-[14px] md:px-[32px];
//   @apply active:shadow-[inset_0_6px_0_0_rgba(0,0,0,0.25)] active:bg-cc-black;
//   @apply focus-visible:shadow-[0_0_0_4px_theme(colors.cc.yellow),inset_0_0_0_4px_theme(colors.cc.black)];
// }

// Formie
.fui-legend {
  @apply heading-sm mb-sm;
}

.fui-instructions {
  @apply mb-sm text-cc-black;

  & > *:last-child {
    @apply mb-0;
  }
}

.fui-fieldset {
  @apply flex flex-col mb-5 md:mb-5-lg;

  &.fui-subfield-fieldset {
    @apply mb-[calc(theme(margin.5)-theme(margin.4))] md:mb-[calc(theme(margin.5-lg)-theme(margin.4-lg))];
  }
}

.fui-checkbox,
.fui-radio {
  @apply flex mb-2xs;

  & > input {
    @apply mr-xs;

    & + label {
      @apply mt-[9px] mb-0 md:mt-[6px];
    }
  }
}

.fui-heading {
  @apply mt-0;
}

.fui-btn-wrapper {
  @apply mt-5 md:mt-5-lg;
}

.fui-required {
  @apply hidden;
}

.fui-error-message {
  @apply font-semibold -mt-[10px] pl-[28px] md:pl-[32px];
  @apply bg-[url('../images/icons/error.svg')] bg-no-repeat bg-[left_center] bg-[length:20px_20px] md:bg-[length:24px_24px];
}

.fui-fieldset + .fui-error-message {
  @apply font-semibold -mt-[24px]
}

.fui-field.fui-error {
  @apply border-l-4 border-l-error-red p-[16px] mb-4 md:mb-4-lg;

  input[type="date"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  select,
  textarea {
    @apply border-error-red;
  }
}

.fui-alert {
  @apply font-semibold border-4 border-error-red p-[16px] mb-8 md:p-[24px] md:mb-8-lg;

  p {
    @apply last:mb-0;
  }
}

.fui-type-multi-line-text {
  .fui-input-wrapper {
    @apply mb-4 md:mb-4-lg;
  }

  textarea {
    @apply mb-3xs;
  }

  .fui-limit-text {
    @apply text-cc-black;
  }
}