.matrix:last-child {
  & > div:last-child > div:last-child {
    @apply mb-0;

    & > *:last-child {
      @apply mb-0;
    }
  }
}

.matrix:first-child {
  & > div:first-child > div:first-child {
     @apply mt-0;

     & > *:first-child {
      @apply mt-0;
     }
  }
}

.matrix--heading--h4 + .matrix--content-text > div > div > ol,
.matrix--heading--h4 + .matrix--content-text > div > div > ul {
  &:first-child {
    @apply mt-6 sm:mt-6-lg;
  }
}

.matrix--content-action-link + .matrix--content-action-link {
  div > div > div {
    @apply -mt-[14px] sm:-mt-[28px];
  }
}